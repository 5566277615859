import React, { useEffect, useState, Fragment } from 'react';
import './App.css';
import Headers from './components/header';
import Home from './modules/Home';
import { Routes, Route } from 'react-router-dom';
import Product from './modules/product';
import Categories from "./modules/categories";
import CategorieProducts from './modules/c_products';
import Axios from 'axios';
import * as Constants from '../src/api/constants';
import { Helmet } from 'react-helmet';
import OgImage from './assets/logo192.png';
import UIImage from './assets/logo512.png';
import InstallPrompt from './components/InstallPrompt';
import { BsBackspace } from 'react-icons/bs';

export let ComData;
export let Token;

function App() {

  const [data, setData] = useState('');
  const [tokenOne, setToken] = useState('');

  useEffect(() => {
    const config = {
      headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
    };
    Axios.get(Constants.COMPANYDATA + Constants.C_ID, config)
      .then((response) => {
        const result = response.data.result;
        result.map(items => {
          ComData = items;
          setData(items);
        })
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  

  const description = "Discover and shop the latest trends with our intuitive Bee's Shop catalog app. Browse a wide range of products, from fashion to electronics, and enjoy a seamless shopping experience. Explore curated collections, compare prices, and make secure purchases all in one place."
  const title = "Explore & Shop: Your Ultimate Bee's Shop Catalog App"

  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  const Navtoggle = () => {
    
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const contentClassName = isSidebarOpen ? "!pl-[240px]" : "w-full";


  return (
    <Fragment>
    
    <div className='text-gray-600 w-full '>
      <nav
        id="sidenav-2"
        className={`fixed left-0 top-0 z-[48] h-screen w-60 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } overflow-hidden bg-[#EA1920] shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] ...`}

        data-te-sidenav-init
        data-te-sidenav-hidden="false"
        data-te-sidenav-mode="side"
        data-te-sidenav-content="#content">
        <button
          className="mt-10 inline-block rounded bg-primary px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
          data-te-sidenav-toggle-ref
          data-te-target="#sidenav-3"
          aria-controls="#sidenav-3"
          aria-haspopup="true"
          style={{position: "absolute", right: 10, top: -15}}
          onClick={toggleSidebar}>
          <span className="block [&>svg]:h-5 [&>svg]:w-5 [&>svg]:text-white">
            <BsBackspace />
          </span>
        </button>
        <ul className="relative m-0 list-none px-[0.2rem]" data-te-sidenav-menu-ref style={{marginTop: 75}}>
        <InstallPrompt />
        </ul>
      </nav>
      


      
      <Headers data={data} tokenOne={tokenOne} toggleSidebar={toggleSidebar}/>
      <Routes>
        <Route path="/" element={<Home tokenOne={tokenOne}/>} />
        <Route path="/products/:name/:id" element={<Product tokenOne={tokenOne}/>} />
        <Route path="/category" element={<Categories tokenOne={tokenOne}/>} />
        <Route path="/category/:name/:id" element={<CategorieProducts tokenOne={tokenOne}/>} />
      </Routes>

    </div>
</Fragment>
  );
}

export default App;
