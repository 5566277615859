import React from 'react';
import banner from '../../assets/FF-banner (1).jpg';
import logo1 from '../../assets/logo11.jpg';
import logo2 from '../../assets/logo12.jpg';
import logo3 from '../../assets/logo13.png';
import logo4 from '../../assets/logo14.png';

const MobileTopView = ({ Image, setShowMobView }) => {

    const CloseM_View =()=>{
        localStorage.setItem('lastClosedTime', new Date().getTime());
        setShowMobView(false);
    }

    const windowHeight = window.innerHeight;

    return (
        <div className="min-h-full md:hidden" style={{ position: 'absolute', zIndex: 9999, width: '100%', marginTop: -120 }}>
            <nav className="bg-gray-800">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-between">
                        <div className="container mx-auto flex flex-wrap px-6 pb-3 pt-1 flex-col md:flex-row items-center">
                            <img className="h-8" src={Image} alt="logo" width={220} />
                        </div>
                    </div>
                </div>
            </nav>

            <div style={{ backgroundColor: '#FFF', height: windowHeight+20 }}>
                <div className="px-4 sm:px-0 pt-2">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">About Us</h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 pr-4">Discover an extensive selection of products in our intuitive catalog app. From cutting-edge electronics and stylish fashion to innovative gadgets and elegant home decor, our platform offers a seamless browsing experience. Explore, compare, and find your next must-have item with ease!</p>
                </div>
                <img src={banner} style={{ width: '100%', paddingLeft: 10, paddingRight: 10, paddingTop: 10 }} />

                <div className="flex h-16 items-center justify-between">
                    <div className="container mx-auto flex flex-wrap px-6 pb-3 pt-1 flex-col md:flex-row items-center">
                        <button onClick={CloseM_View} class="rounded-2xl  bg-teal-600 p-2 pl-7 pr-7 text-white mt-14">Continue</button>
                    </div>
                </div>

                {/* <div class="p-2 w-full pt-4 border-t border-gray-200 text-center mt-14">
                                    
                                    <span class="inline-flex">
                                        <a class="text-gray-500">
                                            
                                            <img src={logo1} style={{ width: 60, height: 30, }} />
                                        </a>
                                        <a class="ml-4 text-gray-500">
                                            
                                            <img src={logo2} style={{ width: 70, height: 25, }} />

                                        </a>
                                        <a class="ml-4 text-gray-500">
                                            
                                            <img src={logo3} style={{ width: 60, height: 30, }} />

                                        </a>
                                        <a class="ml-4 text-gray-500">
                                            
                                            <img src={logo4} style={{ width: 40, height: 30, }} />

                                        </a>
                                    </span>
                                </div> */}
            </div>
        </div>
    )
}

export default MobileTopView;