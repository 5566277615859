import React from 'react';
import GIF from '../../assets/prod_loading.gif';

const SvgLoadingAnimation = () => (
    <div>
        <img src={GIF} className='' />
    </div>
);

export default SvgLoadingAnimation;
