import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import * as Constants from '../../api/constants';
import Axios from 'axios';
import { BiArrowBack } from 'react-icons/bi';
import Loader from '../../components/loader';
import { Img } from 'react-image';
import LoadAni from '../../components/loadani';
import noPreview from '../../assets/no_preview.png';
import he from 'he';

const Categories = () => {

    const [category, setCategory] = useState([]);

    const config = {
        headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
    };
    useEffect(() => {
        Axios.get(Constants.CATEGORIES + Constants.C_ID, config)
            .then((response) => {
                const result = response.data.result;
                setCategory(result);
                localStorage.setItem('CategoriesData', JSON.stringify(result));
            })
            .catch((error) => {
                let collection = localStorage.getItem('SwipperData');
                setCategory(JSON.parse(collection));
            });
    }, []);

    return (
        <>
            {category.length > 0 ? (
                <div >
                    <section className="main-one text-gray-600 body-font mb-20 items-center justify-center" style={{ marginTop: 20, alignItems: 'center', }}>
                        <style>
                            {`
                                    @media (max-width: 350px) {
                                        .custom-sm\\:w-1\\/2 {
                                        width: 48%;
                                        margin: 1%; /* Add margin for spacing */
                                        }
                                    }

                                    @media (min-width: 351px) and (max-width: 665px) {
                                        .custom-md\\:w-1\\/2 {
                                        width: 48%;
                                        margin: 1%; /* Add margin for spacing */
                                        }
                                    }

                                    @media (min-width: 670px) {
                                        .custom-lg\\:w-1\\/2 {
                                        width: 32%;
                                        margin-left: 1.3333333% /* Add margin for spacing */
                                        }
                                        .main-one{
                                        padding-right: 13px;
                                        padding-left: 5px
                                        }
                                    }

                                    @media (min-width: 670px) and (max-width: 917px) {
                                        .custom-lg\\:w-1\\/2 {
                                        width: 32%;
                                        margin-left: 1.3333333% /* Add margin for spacing */
                                        }
                                        .main-one{
                                            padding-right: 15px;
                                        }
                                    }
                                    
                            `}
                        </style>
                        <div className="container items-center">
                            <div className="flow-root pr-6">
                                <p className="float-left text-black text-2xl p-2 pb-5">Categories List </p>
                                <Link to={'/'} className="flex text-lg float-right cursor-pointer mt-[10px]"><BiArrowBack style={{ margin: 5 }} /> Back to Home</Link>
                            </div>

                            <div className="flex flex-wrap">
                                {category.map((product) => {
                                    let newImage = '';
                                    if (product.image == null || product.image == "") {
                                        newImage = false;
                                    } else {
                                        let match = /\.(\w+)$/.exec(product.image);
                                        let type = match ? `.${match[1]}` : ``;
                                        let name = product.image.substring(product.image.lastIndexOf('.') + 0, product.image) + '_thumb';
                                        newImage = name + type;
                                    }
                                    return (
                                        <Link to={`/category/${product.category_name}/${product.id}`}
                                            className="w-full md:w-1/2 lg:w-1/3 sm:w-1/2 mt-2 custom-sm:w-1/2 custom-md:w-1/2 custom-lg:w-1/2 border-2 rounded-md hover:border-slate-500 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 ..."
                                            style={{ marginBottom: 10 }}>
                                            <a className="block text-sm relative h-52 rounded overflow-hidden">
                                                <Img
                                                    alt={product.category_name}
                                                    loader={<div><LoadAni /></div>}
                                                    className=" object-fill  object-center w-full h-full  block"
                                                    src={newImage ? Constants.AdminUrl + newImage : noPreview}
                                                />
                                            </a>
                                            <div className="mt-2  p-2">
                                                <h3 className="text-gray-500 uppercase text-center text-xs font-bold tracking-widest title-font mb-1">
                                                    <div dangerouslySetInnerHTML={{ __html: he.decode(product?.category_name?.slice(0,60)) }}></div>
                                                </h3>
                                            </div>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    </section>
                    );
                </div>
            ) : (
                <Loader />
            )}
        </>
    );



};

export default Categories;
