import React, { useState, useEffect } from 'react'
import Axios from "axios";
import * as Constans from "../../api/constants";
import logo1 from '../../assets/logo11.jpg';
import logo2 from '../../assets/logo12.jpg';
import logo3 from '../../assets/logo13.png';
import logo4 from '../../assets/logo14.png';
import banner from '../../assets/FF-banner (1).jpg';


const RightSide = () => {

    const [comData, setComData] = useState([]);
    const [data, setData] = useState('');
    const [mapdata, setMapData] = useState([]);

    useEffect(() => {

        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
        };

        const apiKey = "AIzaSyBTzkpVROjq7NQIfxUgFYalr7OerCe3lbs";

        Axios.get(Constans.COMPANYDATA + Constans.C_ID, config)
            .then((response) => {
                const result = response.data.result;
                setData(result);

                result.map(item => {
                    setComData(item);
                    setMapData(`https://www.google.com/maps/embed/v1/view?key=${apiKey}&center=${item?.latitude},${item?.longitude}&zoom=14`)
                })

            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

    }, []);

    if (!Object.keys(data).length > 0)
        return (
            <div className="flex justify-center align-middle mt-20">
                <span className="relative align-middle justify-center flex h-20 w-20">
                    <div role="status">
                        <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-red-500 fill-red-700" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                </span>
            </div>
        );

    if (Object.keys(data).length > 0)
        return (
            <><section className="text-gray-600 body-font relative" style={{ marginTop: 0 }}>
                {/* <img style={{ backgroundColor: '#EA1920' }} className="h-auto max-w-[150px] rounded-xl  m-5 px-4 py-2 mx-auto" src={Constans.AdminUrl + comData.company_logo} alt="logo" /> */}
                <img src={banner} style={{ width: '100%', paddingLeft: 10, paddingRight: 10, paddingTop: 10 }} />
                <section class="text-gray-600 body-font relative">
                    <div class="container px-5 py-2 mx-auto">
                        <div class="flex flex-col text-center w-full mb-2">
                            {/* <h1 style={{ backgroundColor: '#EA1920' }} class="sm:text-3xl text-2xl font-medium title-font mb-4 text-white rounded-md">{comData.company_name}</h1> */}

                            <p><b>About Us</b></p>
                            <p class=" p-2 mx-auto leading-relaxed text-base" style={{}}>Discover an extensive selection of products in our intuitive catalog app. From cutting-edge electronics and stylish fashion to innovative gadgets and elegant home decor, our platform offers a seamless browsing experience. Explore, compare, and find your next must-have item with ease!</p>
                            <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-black"><svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom: -22}} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                            </svg>{comData?.address}</p>

                        </div>
                        <div class="lg:w-1/2 md:w-2/3 mx-auto">
                            <div class="flex flex-wrap -m-2">
                                {/* <div class="p-2 w-1/2">
                                        <div class="relative">
                                            <label for="name" class="leading-7 font-semibold text-sm text-gray-600">Mobile</label>
                                            <p>{comData?.mobile}</p>
                                        </div>
                                    </div>
                                    <div class="p-2 w-1/2">
                                        <div class="relative">
                                            <label for="email" class="leading-7 font-semibold text-sm text-gray-600">Phone</label>
                                            <p>{comData?.phone}</p>
                                        </div>
                                    </div> */}
                                <div class="p-2 w-full">
                                    <div class="relative">
                                        <p className=' text-xs text-gray-400'>Scan to pay</p>
                                        <img
                                            className="h-auto max-w-[130px] mt-1 mx-auto" src={Constans?.AdminUrl + comData.upi_code} alt="qr code" />
                                    </div>
                                </div>

                                {/* <div class="p-2 w-full pt-4 mt-4 border-t border-gray-200 text-center">
                                    <span class="inline-flex">
                                        <a class="text-gray-500">

                                            <img src={logo1} style={{ width: 60, height: 30, }} />
                                        </a>
                                        <a class="ml-4 text-gray-500">

                                            <img src={logo2} style={{ width: 70, height: 25, }} />

                                        </a>
                                        <a class="ml-4 text-gray-500">

                                            <img src={logo3} style={{ width: 60, height: 30, }} />

                                        </a>
                                        <a class="ml-4 text-gray-500">

                                            <img src={logo4} style={{ width: 40, height: 30, }} />

                                        </a>
                                    </span>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </section>

            </section><footer style={{ position: 'absolute', bottom: 0, backgroundColor: '#111' }}
                class="b  text-center w-full dark:bg-neutral-800 lg:text-left">
                    <div
                        class=" p-2  text-neutral-800 dark:bg-neutral-800 ">
                        <a
                            class="text-white cursor-pointer hover:text-slate-200 justify- flex"
                            href={`https://wa.me/+${comData?.mobile}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >Call or WhatsApp: +{comData?.mobile}</a>
                        <img src="https://netbeesconsulting.com/wp-content/uploads/2020/02/netbees_logo.png" className=" h-auto absolute align-middle justify-center flex w-12 right-6 top-2" />

                    </div>

                </footer></>
        )
}

export default RightSide;
