import React, { useEffect, useState } from "react";
import Products from "../../components/products";
import Swiper from "../../components/swiper";
// import Filters from "../../components/filters";
// import MobileNav from "../../components/mobilenav";
import Axios from 'axios';
import * as Constants from '../../api/constants';
import { Helmet } from 'react-helmet';
import OgImage from '../../assets/logo192.png';
// import UIImage from '../../assets/logo512.png';
// import loadable from '@loadable/component';
import OtherComponent from '../../components/loader';
import MobileTopView from "../../components/mobileTop";

const Home = ({ tokenOne }) => {
  const [products, setProducts] = useState([]);

  const config1 = {
    headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
  };


  const [comData, setComData] = useState([]);
  const [showMobView, setShowMobView] = useState(false);

  useEffect(() => {
    // Check if the welcome popup has been closed in the last 24 hours
    const lastClosedTime = localStorage.getItem('lastClosedTime');
    const currentTime = new Date().getTime();

    if (!lastClosedTime || currentTime - lastClosedTime > 24 * 60 * 60 * 1000) {
      // If the popup hasn't been closed in the last 24 hours, show it
      setShowMobView(true);
    }
  }, []);



  useEffect(() => {
    Axios.get(Constants.ALL_PRODUCTS + Constants.C_ID + "/0/0/0", config1)
      .then((response) => {
        const result = response.data.result;
        localStorage.setItem('ProductsAll', JSON.stringify(result));
        setProducts(result);
      })
      .catch((error) => {
        let collection = localStorage.getItem('ProductsAll');
        setProducts(JSON.parse(collection));
      });

    Axios.get(Constants.COMPANYDATA + Constants.C_ID, config1)
      .then((response) => {
        const result = response.data.result;
        result.map(item => {
          setComData(item);
        })

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // const pageTitle = 'Bee\'s Shop | Catalogue App';
    // const pageDescription = 'Supper fast Bee\'s Shop catalog app. Browse a wide range of products, from fashion to electronics, and enjoy a seamless shopping experience.';

    // document.title = pageTitle;
    // const metaDescription = document.querySelector('meta[name="description"]');
    // if (metaDescription) {
    //   metaDescription.setAttribute('content', pageDescription);
    // }

    // const ogTitle = 'Ultimate Bee\'s Shop Catalog App';
    // const ogDescription = 'Discover and shop the latest trends with our intuitive Bee\'s Shop catalog app. Browse a wide range of products, from fashion to electronics, and enjoy a seamless shopping experience.';
    // const ogImage = OgImage;
    // const uiImage = UIImage;

    // document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
    // document.querySelector('meta[name="description"]').setAttribute('content', pageDescription);
    // document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
    // document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
    // document.querySelector('meta[property="og:image:alt"]').setAttribute('content', 'App Logo');
    // document.querySelector('meta[property="og:type"]').setAttribute('content', 'website');
    // document.querySelector('meta[property="og:url"]').setAttribute('content', 'https://ecommerce.netbees.com.sg/');
    // document.querySelector('meta[name="twitter:card"]').setAttribute('content', uiImage);
    // document.querySelector('meta[name="twitter:title"]').setAttribute('content', ogTitle);
    // document.querySelector('meta[name="twitter:description"]').setAttribute('content', ogDescription);
    // document.querySelector('meta[name="twitter:image"]').setAttribute('content', uiImage);
  }, []);



  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Bee's Shop | Catalogue App</title>
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <meta name="csrf-token" content="lQQvEupmlvv4CDAbQLh59MOlOQ1DMBEWS5IT3tyX" />
        <meta name="google-site-verification" content="J75iGHB0rbnundb-hunbyYRLHjarWxqGB-nllkMEVOs" />
        <meta name="apple-mobile-web-app-title" content="ecommerce.netbees.com.sg" />
        <meta name="theme-color" content="#111" />
        <meta name="description" content="Supper fast Bee's Shop catalog app. Browse a wide range of products, from fashion to electronics, and enjoy a seamless shopping experience." />
        <meta name="keywords" content="Product Catalog, Online Shopping, Shop Online, E-commerce, Browse, Products, Electronics, Gadgets, Fashion, Home Decor, Appliances, Tech Accessories, Lifestyle Products, Best Sellers, New Arrivals, Deals and Discounts, Buy Online, Explore Products, Compare Prices, Online Store,Shop Now" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="Ultimate Bee's Shop Catalog App" />
        <meta property="og:description" content="Discover and shop the latest trends with our intuitive Bee's Shop catalog app. Browse a wide range of products, from fashion to electronics, and enjoy a seamless shopping experience." />
        <meta property="og:image" content={Constants.AdminUrl + comData.company_logo} />
        <meta property="og:image:alt" content="App Logo" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={Constants.SEO_Url} />
        <meta property="og:image" content={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 630'%3E%3Crect width='100%' height='100%' fill='%23000' /%3E%3Ctext x='50%' y='50%' font-size='48' dy='.3em' fill='%23fff' text-anchor='middle'%3EYour Text Here%3C/text%3E%3C/svg%3E`} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta name="twitter:card" content={Constants.AdminUrl + comData.company_logo} />
        <meta name="twitter:title" content="Ultimate Bee's Shop Catalog App" />
        <meta name="twitter:description" content="Discover and shop the latest trends with our intuitive Bee's Shop catalog app. Browse a wide range of products, from fashion to electronics, and enjoy a seamless shopping experience." />
        <meta name="twitter:image" content={Constants.AdminUrl + comData.company_logo} />
        <meta name="twitter:image:src" content={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 630'%3E%3Crect width='100%' height='100%' fill='%23000' /%3E%3Ctext x='50%' y='50%' font-size='48' dy='.3em' fill='%23fff' text-anchor='middle'%3EYour Text Here%3C/text%3E%3C/svg%3E`} />

      </Helmet>
      {
        showMobView &&
        <MobileTopView
          Image={Constants.AdminUrl + comData.company_logo}
          setShowMobView={setShowMobView}
        />
      }

      <Swiper tokenOne={tokenOne} />

      {products && products.length > 0 ? (
        <>
          <Products products={products} tokenOne={tokenOne} /></>
      ) : (
        <OtherComponent

        />
      )}

    </>
  );
};

export default Home;
