import React from 'react'
import { useNavigate } from "react-router";
import * as Constans from "../../api/constants";
import noPreview from '../../assets/no-preview-300x300-copy.jpg';

const SearchItems = ({ product, isSearch, setSearch, newImage }) => {

    const navigate = useNavigate();
    return (
        <>
            <style>
                {`

                    .MainContent p {
                            font-size: 11px;
                    }
                    @media (max-width: 520px) {
                    .custom-sm {
                        bottom: 10px; /* Add margin for spacing */
                    }
                    .MainCon{
                        margin-top: -25px;
                        margin-bottom: -25px;
                    }
                    .custom-md {
                        top: 110px; /* Add margin for spacing */
                    }
                    .MainContent{
                        margin-top: -25px; 
                        
                    }
                    
                    }

                    @media (min-width: 351px) and (max-width: 780px) {
                    .custom-md {
                        
                        bottom: -40px; /* Add margin for spacing */
                    }
                    .MainCon{
                        margin-top: -25px;
                        
                    }
                    }

                    @media (min-width: 781px) {
                    .custom-lg {
                        bottom: -40px; /* Add margin for spacing */
                    }
                    .MainCon{
                        margin-top: -25px;
                    }
                    
                    }
                    @media (max-width: 520px) {
                    

                            .MainContent h2 {
                            font-size: 11px; /* Adjust font size for smaller screens */
                            max-width: 210px;
                            max-height: 17px;
                            margin-top: 15px;
                            overflow: hidden; /* Hide overflowing text */
                            text-overflow: ellipsis; /* Display an ellipsis (...) to indicate hidden text */
                            }
                    }
                    @media (max-width: 380px) {
                    

                    .MainContent h2 {
                    font-size: 11px; /* Adjust font size for smaller screens */
                    max-width: 110px;
                    max-height: 17px;
                    margin-top: 15px;
                    overflow: hidden; /* Hide overflowing text */
                    text-overflow: ellipsis; /* Display an ellipsis (...) to indicate hidden text */
                    }
            }
`}
            </style>
            <div
                className={`m-auto flex flex-col gap-2 h-4/5 pt-[4px] pb-[4px] rounded-sm shadow-sm bg-white/[0.6] mb-2 mx-6 ${isSearch ? "cursor-pointer hover:bg-black/5" : ""}`}
                onClick={() => {
                    if (isSearch) {
                        setSearch("");
                        navigate(`products/${product.item_name}/${product.id}`);
                    }
                }}
                style={{ zIndex: 9999 }}
            >
                <div className="flex  flex-wrap w-full">
                    <div className="flex flex-wrap xs:flex-nowrap  flex-1  gap-5">
                        <div
                            className={` bg-black/[0.075] ${isSearch ? "h-10 w-10 " : "h-12 w-12"} rounded-full flex `}
                        >
                            <img src={newImage ? Constans.AdminUrl + newImage : noPreview} alt="" className="object-fit w-full " style={{ borderRadius: 500 }} />
                        </div>
                        <div className="flex justify-center flex-col MainContent">
                            <h2 className=" text-base font-semibold MainContent ">{product?.item_name?.slice(0,60)}</h2>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default SearchItems;
