import React from 'react';
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import MobileNav from "./components/mobilenav";
import RightSide from "./modules/rightside";



import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>

      <div className="Main_div">
        <nav className="sidebar">
          <div className="scrollbox">
            <div className="scrollbox-inner">
              <App />
            </div>
          </div>
          <footer style={{ backgroundColor: "transparent" }}>
            <MobileNav />
          </footer>
        </nav>

        <div className="content">
          <div className="content-wrapper">
            <RightSide />

          </div>
        </div>
      </div>

    </React.StrictMode>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
