const BASE_URL = 'https://catelogue.netbees.com.sg/catelogue/api/';

export const AdminUrl = 'https://catelogue.netbees.com.sg/';


export const SEO_Url = 'https://catelogue.netbees.com.sg/';

//https://posdemo.netbees.com.sg/
//https://catelogue.netbees.com.sg/

export const C_ID = 2;

export const ALL_PRODUCTS = BASE_URL + 'Catelogueitems/';
export const ALL_SEARCSH_PRODUCTS = BASE_URL + 'CatelogueSearchitem/';
export const CATEGORIES = BASE_URL + 'category/';
export const COMPANYDATA = BASE_URL + 'cateloguecompanydata/';
export const ITEM_BY_ID = BASE_URL + 'CatelogueItemById/';
export const BRANDS = BASE_URL + 'BrandsBycategoryId/';
export const LOGIN = BASE_URL + 'login/';