// src/components/InstallPrompt.js

import React, { useState, useEffect } from 'react';
import { RiInstallLine } from 'react-icons/ri';
import { HiOutlineInformationCircle } from 'react-icons/hi';


const InstallPrompt = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstall = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (deferredPrompt) {
                deferredPrompt.prompt();
            } //
        }, 4000)
    }, [])

    return (
        <>
            <li className="relative">
                <a onClick={handleInstall}
                    className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-slate-500 hover:text-white hover:outline-none"
                    data-te-sidenav-link-ref>
                    <span
                        className="mr-4 [&>svg]:h-4 [&>svg]:w-4">
                        <RiInstallLine />
                    </span>
                    <span>Install App</span>
                </a>
            </li>
            <li className="relative">
                <a onClick={handleInstall} href='https://netbeesconsulting.com/' 
                    className="flex h-12 cursor-pointer items-center truncate rounded-[5px] px-6 py-4 text-[0.875rem] text-white outline-none transition duration-300 ease-linear hover:bg-slate-500 hover:text-white hover:outline-none "
                    data-te-sidenav-link-ref
                    target='_blank'>
                    <span
                        className="mr-4 [&>svg]:h-4 [&>svg]:w-4">
                        <HiOutlineInformationCircle />
                    </span>
                    <span>About us</span>
                </a>
            </li>
        </>

    );
};

export default InstallPrompt;
