import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { useParams } from "react-router-dom";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import Axios from "axios";
import * as Constans from "../../api/constants";
import "./filterstyles.css";
import AllB from '../../assets/all_brand.png';
import { Img } from 'react-image';
import LoadAni from '../loadani';
import noPreview from '../../assets/no-preview-300x300-copy.jpg';

const Filters = ({ setAllProducts, setNodata, setShow, setnoDataForBrand }) => {

    const { name, id } = useParams();
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const config = {
            headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
          };
        Axios.get(Constans.BRANDS + Constans.C_ID + `/${id}`,config)
            .then((response) => {
                const result = response.data.result;
                setBrands(result)
                localStorage.setItem(name + id, JSON.stringify(result));
            })
            .catch((error) => {
                let collection = localStorage.getItem(name + id);
                setBrands(JSON.parse(collection));
            });

    }, []);

    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideClick = (item, index) => {
        setActiveIndex(index);
        if (item === 0) {
            const config = {
                headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
              };
            Axios.get(Constans.ALL_PRODUCTS + Constans.C_ID + `/${id}` + "/0/0",config)
                .then((response) => {
                    const result = response.data.result;
                    setAllProducts(result);
                    setnoDataForBrand(false)
                    setShow(true);

                    if (result.length === 0) {
                        setnoDataForBrand(true)
                        setShow(false);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        } else {
            const config = {
                headers: { 'x-pos-netbees-token': `Basic ${process.env.REACT_APP_API_KEY}` }
              };
            Axios.get(Constans.ALL_PRODUCTS + Constans.C_ID + `/${id}/${item.id}` + "/0",config)
                .then((response) => {
                    const result = response.data.result;
                    setAllProducts(result);
                    setnoDataForBrand(false)
                    setShow(true);

                    if (result.length === 0) {
                        setnoDataForBrand(true)
                        setShow(false);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    };

    return (
        // <div>
        //     <Swiper
        //         slidesPerView={4}
        //         spaceBetween={10}
        //         freeMode={true}
        //         pagination={{
        //             clickable: true,
        //         }}
        //         navigation={true}
        //         autoplay={{
        //             delay: 2500,
        //             disableOnInteraction: false,
        //         }}
        //         modules={[Autoplay, Navigation]}
        //         className="mySwiper"
        //         style={{marginBottom: -80, marginTop: 10}}
        //     >
        //         <SwiperSlide
        //             className={`cursor-pointer drop-shadow ${
        //                 activeIndex === 0 ? 'underline font-bold' : ''
        //             }`}
        //             style={{ borderRadius: 4, fontSize: 13, height: 25 }}
        //             onClick={() => handleSlideClick(0, 0)}
        //         >
        //             All Brands
        //         </SwiperSlide>
        //         {brands.map((item, index) => (
        //             <SwiperSlide
        //                 key={index}
        //                 className={`cursor-pointer drop-shadow ${
        //                     activeIndex === index + 1 ? 'underline font-bold' : ''
        //                 }`}
        //                 style={{ borderRadius: 4, fontSize: 13, height: 25 }}
        //                 onClick={() => handleSlideClick(item,index + 1)}
        //             >
        //                 {item.brand_name}
        //             </SwiperSlide>
        //         ))}
        //     </Swiper>
        // </div>
        <div className="MainCon" style={{ width: '100%', }}>
            <style>
                {`
                    @media (max-width: 520px) {
                        .custom-sm {
                        bottom: 10px; /* Add margin for spacing */
                        }
                        .MainCon{
                        margin-top: -25px;
                        margin-bottom: -50px;
                        }
                        .custom-md {
                        top: 100px; /* Add margin for spacing */
                        }
                        .MainContent{
                        margin-top: -25px; 
                        
                        }
                    }

                    @media (min-width: 351px) and (max-width: 780px) {
                        .custom-md {
                        
                        bottom: -40px; /* Add margin for spacing */
                        }
                        .MainCon{
                        margin-top: -25px;
                        
                        }
                    }

                    @media (min-width: 781px) {
                        .custom-lg {
                        bottom: -20px; /* Add margin for spacing */
                        }
                        .MainCon{
                        margin-top: -25px;
                        margin-bottom: -15px
                        }
                    }
                `}
            </style>
            <Swiper
                slidesPerView={6}
                spaceBetween={10}
                freeMode={true}
                pagination={{
                    clickable: true,
                }}

                autoplay={{
                    delay: 2500,

                }}
                modules={[Autoplay]}
                className="mySwiper"
                style={{ width: '100%', height: 150, paddingRight: 140, marginTop: -10 }}
            >
                <SwiperSlide className="MainContent" style={{ width: '24%', margin: '1%' }} onClick={() => handleSlideClick(0, 0)}>
                    <div className="cursor-pointer text-center drop-shadow-xl">
                        <Img
                            className="h-auto max-w-full imaged rounded-full"
                            src={AllB}
                            style={{ marginTop: 20 }}
                            loader={<div><LoadAni /></div>}
                        />
                    </div>
                    <p
                        style={{
                            position: "absolute",
                            fontSize: 11,
                            fontWeight: "bold",

                            width: '100%',
                            textAlign: 'center',
                        }}
                        className={`custom-sm custom-md custom-lg ${activeIndex === 0 ? 'underline font-bold' : ''
                            }`}
                    >

                        All
                    </p>
                </SwiperSlide>
                {brands.map((item, index) => {
                    return (

                        <SwiperSlide className="MainContent" style={{ width: '24%', margin: '1%' }} onClick={() => handleSlideClick(item, index + 1)}>
                            <div className="cursor-pointer text-center drop-shadow-xl" >

                                <Img
                                    className="h-auto max-w-full imaged rounded-full"
                                    src={item.image ? Constans.AdminUrl + item.image : noPreview}
                                    style={{ marginTop: 20 }}
                                    loader={<div><LoadAni /></div>}
                                />
                            </div>
                            <p
                                style={{
                                    position: "absolute",
                                    fontSize: 11,
                                    fontWeight: "bold",

                                    width: '100%',
                                    textAlign: 'center',
                                }}
                                className={`custom-sm custom-md custom-lg ${activeIndex === index + 1 ? 'underline font-bold' : ''
                                    }`}
                            >

                                {item?.brand_name?.slice(0,20)}
                            </p>
                        </SwiperSlide>

                    );
                })}
            </Swiper>
        </div>



    )
}

export default Filters
